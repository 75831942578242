<script setup>

</script>

<template>
    <footer class="footer">
        <div class="footer-top">
            <div class="footer-top__bg" style="background-image: url(/img/footer-bg.png);"></div>
            <div class="container">
                <div class="footer-top__title">Contact us</div>
                <div class="footer-top__content">
                    <div class="footer-top__col">
                        <div class="footer-top__text">Ready to see how innovative app can benefit your community? Contact us</div>
                    </div>
                    <div class="footer-top__col">
                        <div class="footer-top__location">Kazakhstan, Astana</div>
                        <a :href="`tel:+7(777) 775 02 20`" class="footer-top__phone">+7(777) 775 02 20</a>
                        <div class="footer-top__contact button button--secondary">Contact with us</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="footer-bottom__content">
                <div class="footer-bottom__copy">© 2024 - Geonomix</div>
                <div class="footer-bottom__nav">
                    <nav class="footer-nav">
                        <ul class="footer-nav__list">
                            <div class="footer-nav__item">
                                <NuxtLink :to="`/policy`" class="footer-nav__link">Privacy Policy</NuxtLink>
                            </div>
                            <div class="footer-nav__item">
                                <NuxtLink :to="`/terms`" class="footer-nav__link">Terms and Conditions</NuxtLink>
                            </div>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </footer>
</template>

<style lang="scss" scoped>
.footer-top {
    position: relative;
    padding: 60px 0;
    background: linear-gradient(100deg, #F7F7F9 8.5%, #C7EBFF 100%), #F7F7F9;
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;
    }
    &__title {
        position: relative;
        color: #14223D;
        font-size: 24px;
        font-weight: 700;
        line-height: 34px;
        margin-bottom: 25px;
        z-index: 1;
    }
    &__content {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        z-index: 1;
    }
    &__col {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        &:first-child {
            margin-right: 10px;
        }
    }
    &__text {
        color: #14223D;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }
    &__location {
        color: #14223D;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 10px;
    }
    &__phone {
        color: #14223D;
        font-size: 24px;
        font-weight: 400;
        line-height: 34px; 
        text-decoration: none;
        margin-bottom: 25px;
    }
    &__contact {
        width: 150px;
        background: white;
    }
}
.footer-bottom {
    padding: 26px 40px;
    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__copy {
        color: #7185AC;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
    &__nav {
        .footer-nav {
            &__list {
                list-style-type: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            &__item {
                margin-left: 30px;
            }
            &__link {
                color: #117EC2;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-decoration: none;
            }
        }
    }
}
</style>