<script>
import Notifications from '~/components/Notifications.vue'
import Header from '~/components/Header.vue'
import Menu from '~/components/Menu.vue'
import Footer from '~/components/Footer.vue'
import RequestDemo from '~/components/RequestDemo.vue'
</script>

<template>
	<div>
        <div class="page">
            <div class="page__header">
                <Header />
            </div>
            <aside class="page__menu">
                <Menu />
            </aside>
			<div class="page__wrapper">
				<main class="page__content">
					<slot />
				</main>
				<div class="page__footer">
					<Footer />
				</div>
			</div>
        </div>
		<Notifications />
		<RequestDemo />
    </div>
</template>

<style lang="scss">
.page {
    min-height: 100vh;
    background: #F7F7F9;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    &__header {
        position: fixed;
        top: 0;
        right: 0;
        width: calc(100% - 200px);
		background: transparent;
        height: 64px;
        border-top-left-radius: 40px;
		z-index: 2;
    }
    &__menu {
        width: 200px;
		min-width: 200px;
        min-height: 100vh;
    }
	&__wrapper {
		position: relative;
		width: calc(100% - 200px);
		z-index: 0;
	}
    &__content {
        min-height: 100vh;
        width: 100%;
        background: white;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
    }
}
</style>